(function($){

	var preLoadedClass = 'pre-loaded';
	var loadingClass = 'loading';
	var transitionClass = 'transition';

	if (!$('.ie8').length)
	{
		// Set video loops to fade-in after loading
		$('img.' + preLoadedClass).each(function(i, obj) {

			setTimeout($.proxy(function() {

				var $preLoaded = $(this);
				$preLoaded
					.clone()
					.one('load', function() {
						var $this = $(this);

						// Remove loading class from full-image and pre-loaded image completely
						$(this)
							.removeClass(loadingClass)
							.addClass(transitionClass);

						setTimeout($.proxy(function() {
							$(this)
								.removeClass(transitionClass)
								.siblings('.' + preLoadedClass)
									.remove();
						}, this), 3000);

					})
					.addClass(loadingClass)
					.addClass('full-image')
					.removeClass(preLoadedClass)
					.attr('src', $preLoaded.attr('data-src'))
					.removeAttr('data-src')
					.insertAfter($preLoaded);

			}, this), i * 10);

			setTimeout(function() {
				$('.delay-load-background').each(function() {
					var $this = $(this);
					$this
						.attr('style', $this.attr('data-style'))
						.removeAttr('data-style');
				});
			}, 220);
		});
	}
	else
	{
		// Keep things simple for IE8 (and below...)
		$('img.' + preLoadedClass).each(function(i, obj) {
			var $preLoaded = $(this);
			$preLoaded
				.attr('src', $preLoaded.attr('data-src'))
				.removeClass(preLoadedClass);
		});
	}

})(jQuery);
