(function($){

	// Animate back to top action
	$('.top-link').on('click', function(event) {
		$('html, body')
			.stop()
			.animate({ scrollTop: 0 }, 600);
		event.preventDefault();
	});

})(jQuery);
