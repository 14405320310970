(function($){

	var resizeMenu = function() {
		$('.menu-main-container')
			.height($('.menu-main').height());
	};

	// Handle opening and closing the menu
	$('.hamburger').on('click', function(event) {
		$('.header')
			.toggleClass('menu-expanded');
		// Force menu resize
		resizeMenu();
		event.preventDefault();
	});

	// Resize menu on window resize (in unlikely event long titles flow down a line)
	$(window).on('resize', resizeMenu);

})(jQuery);
