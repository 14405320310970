(function($){

	// Getlist of audio files
	var filesList = window.audioFiles;

	// Check for list of audio files and start playing / playback cycle
	if (filesList && filesList.length)
	{
		// Reference to audio/howler object
		var audioPlayer;

		// Save $ refs
		var $controls = $('.background-audio-player .controls');
		var $playButton = $controls.find('.control.play');
		var $pauseButton = $controls.find('.control.pause');

		// Common vars
		var audioIndex = 0;
		var audioVolume = 0.8;

		// Common constants
		var hiddenClass = 'hidden';
		var playingClass = 'playing';
		var pausedClass = 'paused';
		var bufferingClass = 'buffering';
		var errorClass = 'error';
		var controlClasses = [playingClass, pausedClass, bufferingClass].join(' ');
		var fadeDuration = 2000;

		// Control listeners
		$playButton.on('click', function(event) {
			if (audioPlayer) {
				$controls
					.removeClass(controlClasses)
					.addClass(bufferingClass);
				audioPlayer.play();
			}
		});
		$pauseButton.on('click', function(event) {
			if (audioPlayer) {
				$controls
					.removeClass(controlClasses)
					.addClass(pausedClass);
				audioPlayer.pause();
			}
		});
		$controls.find('.control').on('click', function(event) {
			event.preventDefault();
		});

		// Load next track and play
		var playNext = function()
		{
			var audioObj = audioFiles[audioIndex];

			if (audioObj && audioObj.files.length && audioObj.settings)
			{
				// Capture settings / meta
				var autoplay = audioObj.settings && audioObj.settings.autoplay == 'autoplay';
				var title = audioObj.settings ? audioObj.settings.title : '';
				var caption = audioObj.settings ? audioObj.settings.caption : '';

				// Stop existing player if set
				if (audioPlayer)
				{
					audioPlayer.stop();
				}

				// Initialise new audio/howler object
				audioPlayer = new Howl({
					urls: audioObj.files,
					preload: !autoplay,
					autoplay: autoplay,
					loop: true,
					html5: true,
					volume: audioVolume,
					onend: function() {
						// Load next file if set, otherwise will default to loop
						if (filesList.length > 1)
							playNext();
					},
					onload: function() {

					},
					onloaderror: function() {
						$controls
							.removeClass(controlClasses)
							.addClass(errorClass);
					},
					onplay: function() {
						audioPlayer.fade(0, audioVolume, fadeDuration);
						$controls
							.removeClass(controlClasses)
							.addClass(playingClass);
					},
					onpause: function() {
						// Show pause button
						$controls
							.removeClass(controlClasses)
							.addClass(pausedClass);
					}
				});

				// If autoplay, set state to buffering
				if (autoplay) {
					$controls
						.removeClass(controlClasses)
						.addClass(bufferingClass);

					// Force call to play
					audioPlayer.play();
				}

				// Set meta data in player
				$('.background-audio-player .title').text(title);
				$('.background-audio-player .caption').text(caption);

				// Save global reference to howler object
				window.backgroundAudio = audioPlayer;

				// Progress audio index
				audioIndex++;
			}
		};

		// Play first
		playNext();
	}

})(jQuery);
