(function($){

    // Show scroll hint on projects with more than 1 image that require scrolling
    if ($('.layout').length) {

        var scrollEnabled = true;
        var scrollEnabledTarget;
        var scrollHintInAnimation = 'slideInUp';
        var scrollHintOutAnimation = 'slideOutDown';

        var performScroll = function() {

            var hasScrolled = false,
                scrollDuration = 600;

            // Find first item that overlaps page but is not within top threshold
            $('.layout-item').each(function() {
                var $this = $(this);
                var offset = $this.offset();
                var base = offset.top + $this.outerHeight();
                var $window = $(window);
                var windowHeight = $window.height();
                var scrollTop = $window.scrollTop();
                var baseRelative = base - scrollTop - windowHeight;

                if (baseRelative > 0 && offset.top > scrollTop + windowHeight * 0.1)
                {
                    // Animate scroll to object
                    $('html, body')
                        .stop()
                        .animate({ scrollTop: offset.top }, scrollDuration);

                    // Mark as scrolled (will not fallback)
                    hasScrolled = true;

                    // Break loop
                    return false;
                }
            });

            if (!hasScrolled)
            {
                $('html, body')
                    .stop()
                    .animate({ scrollTop: $('.project-footer').offset().top }, scrollDuration);
            }
        };

        var checkScrollHint = function() {

            var $window = $(window);
            var $lastItem = $('.layout-item').last();
            var windowBase = $window.scrollTop() + $window.height();

            if ($lastItem.length && windowBase <= $lastItem.offset().top + $lastItem.outerHeight()) {

                // Only add if scroll hint does not already exist
                var $scrollHint = $('.scroll-hint');
                if (!$scrollHint.length) {
                    $scrollHint = $('<div>')
                        .addClass('scroll-hint active animated ' + scrollHintInAnimation)
                        .attr('title', 'Scroll down for more...')
                        .on('click', performScroll)
                        .appendTo('.page');
                }
                else {
                    // Will re-animate in if animated out
                    $scrollHint
                        .removeClass(scrollHintOutAnimation)
                        .addClass(scrollHintInAnimation);
                }
            }
            else {
                hideScrollHint();
            }
        };

        var hideScrollHint = function() {
            // Animate out (if added)
            $('.scroll-hint')
                .removeClass(scrollHintInAnimation)
                .addClass(scrollHintOutAnimation);
        };

        var hideAndDisableScrollHint = function() {
            // Disable showing scroll hint
            scrollEnabled = false;

            // Hide scroll hint
            hideScrollHint();
        };

        // Listen for scrolling to hide scroll hint (consider throttling)
        $(window).on('scroll', function(e) {
            checkScrollHint();
        });

        // Listen for window resize to re-check scroll
        $(window).on('resize', checkScrollHint);

        // Initial check
        checkScrollHint();
    }

})(jQuery);
