(function($){

	var videoContainers = '.video-loop-container, .layout-loop';

	var videoCallback = function() {
		console.log('canplay');
		console.log($(this));
		$(this)
			.off('canplay', videoCallback)
			.closest(videoContainers)
				.removeClass('loading');
	};

	// Set video loops to fade-in after loading
	$(videoContainers).each(function() {
		var $this = $(this);
		$this.addClass('loading');
		$video = $this.find('video');

		if ($video[0].readyState > 3) {
			setTimeout($.proxy(videoCallback, this), 1);
		}
		else {
			$video
				.on('canplay', videoCallback);

		}

	});

})(jQuery);
